import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { DatePicker } from "@material-ui/pickers";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { FC, useContext, useEffect, useState } from "react";
import { AdminContext } from "../context/admin";
import firebase from "../util/firebase";

import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import Loader from "./Loader";

import AnalyticsPerPage, { AnalyticsEvent } from "./AnalyticsPerPage";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1, 2, 2),
  },
  dates: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "grid",
    gap: theme.spacing(3),
    gridTemplateColumns: "1fr 1fr",
  },
  selectedRow: {
    background: theme.palette.grey[300],
  },
  search: {
    marginBottom: theme.spacing(3),
  },
}));

export type AnalyticsEventData = {
  events: AnalyticsEvent[];
  action: string;
  totalCount: number;
  totalValue: number;
  avgValue: number;
};

const AnalyticsPage: FC = () => {
  const classes = useStyles();
  const today = new Date();
  const aWeekAgo = subDays(today, 7);

  const { isAdmin, currentDomains } = useContext(AdminContext);

  const [dateFrom, setDateFrom] = useState<Date>(aWeekAgo);
  const [dateTo, setDateTo] = useState<Date>(today);

  const [isLoading, setIsLoading] = useState(true);
  const [eventData, setEventData] = useState<AnalyticsEventData[]>([]);

  const [selectedEventIndex, setSelectedEventIndex] = useState<number>(-1);

  const fetchEvents = async () => {
    const db = firebase.firestore();

    setIsLoading(true);

    let query = db
      .collection("analytics")
      .where("timestamp", ">", firebase.firestore.Timestamp.fromDate(dateFrom))
      .where("timestamp", "<=", firebase.firestore.Timestamp.fromDate(dateTo))
      .where("type", "==", "event");

    if (!isAdmin) {
      query = query.where("hostname", "in", currentDomains);
    }

    const { docs } = await query.get();

    const groupedEvents = docs.reduce((acc, doc) => {
      const action = doc.get("action");
      const value = doc.get("value");

      const event: AnalyticsEvent = {
        value: doc.get("value"),
        action,
        articleId: doc.get("articleId"),
        pathname: doc.get("pathname"),
        hostname: doc.get("hostname"),
      };

      if (!acc[action]) {
        acc[action] = {
          events: [],
          action,
          totalCount: 0,
          totalValue: 0,
        };
      }

      acc[action].totalCount += 1;
      acc[action].totalValue += value;
      acc[action].events.push(event);

      return acc;
    }, {} as Record<string, Omit<AnalyticsEventData, "avgValue">>);

    const newEventData: AnalyticsEventData[] = Object.values(groupedEvents).map(
      (groupedEvent) => {
        return {
          ...groupedEvent,
          avgValue: groupedEvent.totalValue / groupedEvent.totalCount,
        };
      }
    );

    setEventData(newEventData);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo, currentDomains]);

  const [search, setSearch] = useState<string>();
  const filteredEventData = search
    ? eventData.filter(({ action }) =>
        action.toLowerCase().includes(search.toLowerCase())
      )
    : eventData;

  return (
    <Grid container spacing={3}>
      <Grid item md={12} lg={6}>
        <Paper className={classes.paper}>
          <Loader isLoading={isLoading} />
          <div className={classes.dates}>
            <DatePicker
              disableFuture
              label="Date from:"
              maxDate={dateTo}
              value={dateFrom}
              onChange={(date) => {
                if (date) {
                  setDateFrom(date);
                }
              }}
            />
            <DatePicker
              disableFuture
              label="Date to:"
              value={dateTo}
              minDate={dateFrom}
              // limit to one month selection
              maxDate={addDays(dateFrom, 31)}
              onChange={(date) => {
                if (date) {
                  setDateTo(date);
                }
              }}
            />
          </div>
          {!!eventData.length && (
            <TextField
              onChange={(e) => setSearch(e.target.value as string)}
              value={search}
              placeholder="Search for an action name"
              className={classes.search}
              fullWidth
            />
          )}
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>Total count</TableCell>
                <TableCell>Total value</TableCell>
                <TableCell>Average value</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredEventData.map((event, i) => {
                return (
                  <TableRow
                    key={event.action}
                    className={
                      i === selectedEventIndex ? classes.selectedRow : ""
                    }
                  >
                    <TableCell>{event.action}</TableCell>
                    <TableCell>{event.totalCount}</TableCell>
                    <TableCell>{event.totalValue.toFixed(2)}</TableCell>
                    <TableCell>{event.avgValue.toFixed(2)}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        size="small"
                        onClick={() => setSelectedEventIndex(i)}
                      >
                        <NavigateNextIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
              {!isLoading && !eventData.length && (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No events found over this time period
                  </TableCell>
                </TableRow>
              )}
              {!isLoading && eventData.length && !filteredEventData.length ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    Search resulted in no results
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item md={12} lg={6}>
        <Paper className={classes.paper}>
          <AnalyticsPerPage
            events={
              filteredEventData[selectedEventIndex]
                ? filteredEventData[selectedEventIndex].events
                : []
            }
            isLoading={isLoading}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AnalyticsPage;
