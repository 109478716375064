import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import firebase from "../util/firebase";
import { FC } from "react";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: theme.palette.primary.light,
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10000,
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: "center",
    maxWidth: 350,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
}));

// eslint-disable-next-line react/prop-types
const Permissions: FC = () => {
  const classes = useStyles();

  const logout = () => {
    firebase.auth().signOut();
  };

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.paper}>
        <Typography variant="h5" className={classes.title}>
          Missing permissions!
        </Typography>
        <Typography>Please contact administrator</Typography>
        <Button
          onClick={logout}
          variant="contained"
          color="primary"
          className={classes.button}
          size="large"
          type="submit"
        >
          Logout
        </Button>
      </Paper>
    </div>
  );
};

export default Permissions;
