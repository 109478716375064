import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AnalyticsPage from "./AnalyticsPage";
import GrammarPage from "./GrammarPage";
import BillingPage from "./BillingPage";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { Suspense } from "react";
import {
  APP_ANALYTICS_PAGE,
  APP_BILLING_PAGE,
  APP_GRAMMAR_PAGE,
  APP_PLAYER_PAGE,
} from "../constants";
import DomainSelect from "./DomainSelect";
import PlayerPage from "./PlayerPage";
import Menu from "./Menu";
import AdminProvider from "../context/admin";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: theme.palette.secondary.main,
    color: "black",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const Router = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AdminProvider>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Listen.technology admin
            </Typography>
            <DomainSelect />
          </Toolbar>
        </AppBar>
        <Menu />
        <main className={classes.content}>
          <Toolbar />
          <Suspense fallback={<div />}>
            <Switch>
              <Route path={APP_ANALYTICS_PAGE}>
                <AnalyticsPage />
              </Route>
              <Route path={APP_GRAMMAR_PAGE}>
                <GrammarPage />
              </Route>
              <Route path={APP_BILLING_PAGE}>
                <BillingPage />
              </Route>
              <Route path={APP_PLAYER_PAGE}>
                <PlayerPage />
              </Route>
              <Redirect to={APP_GRAMMAR_PAGE} />
            </Switch>
          </Suspense>
        </main>
      </AdminProvider>
    </div>
  );
};

export default Router;
