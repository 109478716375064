import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import TimelineIcon from "@material-ui/icons/Timeline";
import SpellcheckIcon from "@material-ui/icons/Spellcheck";
import PaymentIcon from "@material-ui/icons/Payment";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory, withRouter } from "react-router-dom";
import { useCallback, useContext } from "react";
import {
  APP_ANALYTICS_PAGE,
  APP_BILLING_PAGE,
  APP_GRAMMAR_PAGE,
  APP_PLAYER_PAGE,
  // APP_SUPPORT_PAGE,
} from "../constants";
import { AdminContext } from "../context/admin";
import firebase from "../util/firebase";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
}));

const Menu = () => {
  const classes = useStyles();
  const history = useHistory();
  const { isAdmin, currentDomains } = useContext(AdminContext);

  const logout = () => {
    firebase.auth().signOut();
  };

  const redirectToPath = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const getIsSelected = useCallback(
    (path: string) => {
      return history.location.pathname.includes(path);
    },
    [history]
  );

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List>
          {isAdmin && (
            <ListItem
              button
              onClick={() => redirectToPath(APP_ANALYTICS_PAGE)}
              selected={getIsSelected(APP_ANALYTICS_PAGE)}
            >
              <ListItemIcon>
                <TimelineIcon />
              </ListItemIcon>
              <ListItemText primary="Analytics" />
            </ListItem>
          )}
          <ListItem
            button
            onClick={() => redirectToPath(APP_GRAMMAR_PAGE)}
            selected={getIsSelected(APP_GRAMMAR_PAGE)}
          >
            <ListItemIcon>
              <SpellcheckIcon />
            </ListItemIcon>
            <ListItemText primary="Grammar" />
          </ListItem>
          {/* <ListItem
            button
            onClick={() => redirectToPath(APP_BILLING_PAGE)}
            selected={getIsSelected(APP_BILLING_PAGE)}
          >
            <ListItemIcon>
              <PaymentIcon />
            </ListItemIcon>
            <ListItemText primary="Billing" />
          </ListItem>
          <ListItem
            button
            onClick={() => redirectToPath(APP_PLAYER_PAGE)}
            selected={getIsSelected(APP_PLAYER_PAGE)}
          >
            <ListItemIcon>
              <PlayCircleFilledIcon />
            </ListItemIcon>
            <ListItemText primary="Player settings" />
          </ListItem> */}
        </List>
        <Divider />
        <List>
          <ListItem button onClick={logout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default withRouter(Menu);
