import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import firebase from "../util/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

type Session = {
  roles?: string[];
  domains?: string[];
  user?: firebase.User;
  loading: boolean;
};
export const SessionContext = createContext<Session>({ loading: true });

export const useSession = () => {
  return useContext(SessionContext);
};

type SessionProviderProps = {
  children: ReactNode;
};

export const SessionProvider = ({ children }: SessionProviderProps) => {
  const [user, loading] = useAuthState(firebase.auth());
  const [roles, setRoles] = useState<string[]>([]);
  const [domains, setDomains] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(loading);

  const fetchFullUser = useCallback(async () => {
    if (!loading && !user?.uid) {
      setIsLoading(loading);
      return;
    }

    if (!user?.uid) {
      return;
    }

    const db = firebase.firestore();
    const userDoc = db.collection("user").doc(user?.uid);
    const fullUserResult = await userDoc.get();
    const data = fullUserResult.data() as {
      roles?: string[];
      domains?: string[];
      email?: string;
    };

    if (!fullUserResult.exists) {
      userDoc.set({ roles: [], domains: [] });
    }

    if (data && !data.email && user.email) {
      userDoc.update({ email: user?.email });
    }

    if (data) {
      setRoles(data.roles || []);
      setDomains(data.domains || []);
    }

    setIsLoading(false);
  }, [user, loading]);

  useEffect(() => {
    fetchFullUser();
  }, [fetchFullUser]);

  useEffect(() => {
    fetchFullUser();
  }, [fetchFullUser, user?.uid]);

  if (isLoading) {
    return null;
  }

  return (
    <SessionContext.Provider
      value={{ roles, domains, user, loading: isLoading }}
    >
      {children}
    </SessionContext.Provider>
  );
};
