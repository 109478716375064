import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { FC, useContext, useState } from "react";
import playWord from "../util/playWord";
import Loader from "./Loader";
import firebase from "../util/firebase";
import { AdminContext } from "../context/admin";
import { useSnackbar } from "material-ui-snackbar-provider";

const useStyles = makeStyles((theme) => ({
  newWord: {
    display: "grid",
    gap: theme.spacing(2),
    position: "sticky",
  },
  playField: {
    display: "flex",
    alignItems: "center",
  },
  playFields: {
    display: "grid",
    gap: theme.spacing(2),
    gridTemplateColumns: "1fr 1fr",
  },
  playInput: {
    flexGrow: 1,
    marginRight: theme.spacing(1),
  },
}));

const AddNewWord: FC<{ onNewWordCreate?: () => void }> = ({
  // eslint-disable-next-line react/prop-types
  onNewWordCreate,
}) => {
  const { showMessage } = useSnackbar();
  const classes = useStyles();
  const { isAdmin, currentDomains } = useContext(AdminContext);
  const [lang, setLang] = useState<string>("");
  const [oldW, setOldW] = useState<string>("");
  const [newW, setNewW] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const playOldW = async () => {
    setIsLoading(true);
    try {
      await playWord(oldW, lang, true);
    } catch (e) {
      if (e instanceof Error) {
        showMessage(e.message);
      } else {
        showMessage(String(e));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const playNewW = async () => {
    setIsLoading(true);
    try {
      await playWord(newW, lang, false);
    } catch (e) {
      if (e instanceof Error) {
        showMessage(e.message);
      } else {
        showMessage(String(e));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const createNewWord = async () => {
    const db = firebase.firestore();
    const newDoc = db.collection("words").doc();

    setIsLoading(true);

    await newDoc.set({
      original: oldW,
      replacement: newW,
      language: lang,
      isDisabled: false,
      isApproved: isAdmin,
      hostnames: currentDomains,
      updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
      createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
    });

    setIsLoading(false);
    setOldW("");
    setNewW("");

    if (onNewWordCreate) {
      onNewWordCreate();
    }
  };

  return (
    <div className={classes.newWord}>
      <Loader isLoading={isLoading} />
      <Typography variant="h6">Add new word</Typography>
      <Select
        displayEmpty
        value={lang}
        onChange={(e) => setLang(e.target.value as string)}
      >
        <MenuItem value="" disabled>
          Select language
        </MenuItem>
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="ru">Russian</MenuItem>
        <MenuItem value="lv">Latvian</MenuItem>
      </Select>
      <div className={classes.playFields}>
        <div className={classes.playField}>
          <TextField
            className={classes.playInput}
            placeholder="The original word"
            value={oldW}
            onChange={(e) => setOldW(e.target.value)}
          />
          <IconButton
            disabled={!oldW.length || !lang}
            color="primary"
            size="small"
            onClick={playOldW}
          >
            <PlayCircleFilledIcon />
          </IconButton>
        </div>
        <div className={classes.playField}>
          <TextField
            className={classes.playInput}
            placeholder="The word replacement"
            value={newW}
            onChange={(e) => setNewW(e.target.value)}
          />
          <IconButton
            disabled={!newW.length || !lang}
            color="primary"
            size="small"
            onClick={playNewW}
          >
            <PlayCircleFilledIcon />
          </IconButton>
        </div>
      </div>
      <Button
        variant="contained"
        onClick={createNewWord}
        disabled={!(oldW && newW && lang)}
      >
        Add new word
      </Button>
    </div>
  );
};

export default AddNewWord;
