import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { FC, useState, useEffect } from "react";

// eslint-disable-next-line import/no-cycle

export type AnalyticsEvent = {
  value: number;
  action: string;
  articleId: string;
  pathname: string;
  hostname: string;
};

export type AnalyticsEventPageData = {
  pathnames: string[];
  totalCount: number;
  totalValue: number;
  avgValue: number;
};

const useStyles = makeStyles((theme) => ({
  search: {
    marginBottom: theme.spacing(1),
  },
  title: {
    marginTop: theme.spacing(1),
  },
}));

const AnalyticsPerPage: FC<{
  events: AnalyticsEvent[];
  isLoading: boolean;
}> = ({ events = [], isLoading = true }) => {
  const classes = useStyles();
  const [search, setSearch] = useState<string>();

  const groupedPages = events.reduce((acc, event) => {
    if (!acc[event.articleId]) {
      acc[event.articleId] = {
        pathnames: [],
        totalCount: 0,
        totalValue: 0,
      };
    }

    if (!acc[event.articleId].pathnames.includes(event.pathname)) {
      acc[event.articleId].pathnames.push(event.pathname);
    }

    acc[event.articleId].totalCount += 1;
    acc[event.articleId].totalValue += event.value;

    return acc;
  }, {} as Record<string, Omit<AnalyticsEventPageData, "avgValue">>);

  const eventPageData: AnalyticsEventPageData[] = Object.values(
    groupedPages
  ).map((groupedPage) => ({
    ...groupedPage,
    avgValue: groupedPage.totalValue / groupedPage.totalCount,
  }));

  const filteredEventPageData = search
    ? eventPageData.filter(({ pathnames }) =>
        pathnames.join(", ").toLowerCase().includes(search.toLowerCase())
      )
    : eventPageData;

  if (!eventPageData.length) {
    if (!isLoading) {
      return (
        <div className={classes.title}>
          <Typography>No detailed event overview to display</Typography>
        </div>
      );
    }

    return (
      <div className={classes.title}>
        <Typography>Select an event for detailed overview</Typography>
      </div>
    );
  }

  return (
    <div>
      <TextField
        onChange={(e) => setSearch(e.target.value as string)}
        value={search}
        placeholder="Search for pathname"
        className={classes.search}
        fullWidth
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Pathnames</TableCell>
            <TableCell>Total count</TableCell>
            <TableCell>Total value</TableCell>
            <TableCell>Average value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredEventPageData.map((eventPage, i) => {
            const pathnames = eventPage.pathnames.join(", ");

            return (
              <TableRow key={pathnames}>
                <TableCell>{pathnames}</TableCell>
                <TableCell>{eventPage.totalCount}</TableCell>
                <TableCell>{eventPage.totalValue.toFixed(2)}</TableCell>
                <TableCell>{eventPage.avgValue.toFixed(2)}</TableCell>
              </TableRow>
            );
          })}
          {!filteredEventPageData.length && (
            <TableRow>
              <TableCell colSpan={4}>Search resulted in no results</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default AnalyticsPerPage;
