import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { FC, useContext, useEffect, useState } from "react";
import Loader from "./Loader";
import { AdminContext } from "../context/admin";

const useStyles = makeStyles((theme) => ({
  newWord: {
    display: "grid",
    gap: theme.spacing(2),
    position: "sticky",
  },
}));

const DeleteArticleCache: FC = () => {
  const classes = useStyles();
  const { isAdmin, currentDomains } = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [articleUrl, setArticleUrl] = useState("");

  const cleanCache = async () => {
    setIsLoading(true);

    try {
      await fetch(
        `https://listen.technology/api/delete-synthesized-article?url=${articleUrl}`
      );
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
    setArticleUrl("");
  };

  useEffect(() => {
    try {
      const { hostname } = new URL(articleUrl);

      if (!isAdmin && !currentDomains.includes(hostname)) {
        throw new Error();
      }

      setIsValid(true);
    } catch (_) {
      setIsValid(false);
    }
  }, [articleUrl, currentDomains, isAdmin]);

  return (
    <div className={classes.newWord}>
      <Loader isLoading={isLoading} />
      <Typography variant="h6">Clean article cache</Typography>
      <TextField
        fullWidth
        placeholder="The URL to delete cache of"
        value={articleUrl}
        onChange={(e) => setArticleUrl(e.target.value)}
      />
      <Button
        fullWidth
        variant="contained"
        onClick={cleanCache}
        disabled={!isValid}
      >
        Clean this article cache
      </Button>
    </div>
  );
};

export default DeleteArticleCache;
