const playWord = async (word: string, lang: string, isProcessText = true) => {
  // TODO: implement word play
  const sourceUrl = new URL("https://listen.technology");
  sourceUrl.searchParams.set("text", word);
  sourceUrl.searchParams.set("lang", lang);
  sourceUrl.searchParams.set("process", isProcessText ? "1" : "0");
  sourceUrl.searchParams.set("watermark", "0");
  const targetUrl = new URL("https://listen.technology/api/synthesize-article");
  targetUrl.searchParams.set("url", sourceUrl.href);
  const synthResponse = await fetch(targetUrl.href);
  const {
    data: { url },
  } = await synthResponse.json();

  const tryPlay = async (i = 0) => {
    if (i > 5) {
      throw new Error("Failed to synthesise article");
    }

    const mp3 = await fetch(url);

    if (!mp3.ok) {
      await new Promise<void>((res) => setTimeout(() => res(), 1000));
      await tryPlay(i + 1);
      return;
    }

    const audio = new Audio(url);
    await audio.play();
  };

  await tryPlay();
};

export default playWord;
