import { makeStyles } from "@material-ui/core/styles";
import { FC, useContext } from "react";
import { AdminContext } from "../context/admin";
import Chip from "@material-ui/core/Chip";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useSession } from "../context/useSession";

const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    background: theme.palette.background.paper,
    margin: 1,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

// eslint-disable-next-line react/prop-types
const DomainSelect: FC = () => {
  const classes = useStyles();
  const { domains } = useSession();
  const { isAdmin, currentDomains, setCurrentDomains } = useContext(
    AdminContext
  );

  if (isAdmin || !domains || !domains.length) {
    return null;
  }

  return (
    <Select
      multiple
      disableUnderline
      value={currentDomains}
      onChange={(e) => setCurrentDomains(e.target.value as string[])}
      input={<Input />}
      renderValue={(selected) => (
        <div className={classes.chips}>
          {(selected as string[]).map((value) => (
            <Chip key={value} label={value} className={classes.chip} />
          ))}
        </div>
      )}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
      }}
    >
      {domains.map((domain) => {
        const isChecked = currentDomains.indexOf(domain) > -1;
        const isDisabled = isChecked && currentDomains.length === 1;

        return (
          <MenuItem key={domain} value={domain}>
            <Checkbox disabled={isDisabled} checked={isChecked} />
            <ListItemText primary={domain} />
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default DomainSelect;
