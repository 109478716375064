import { FC, FormEvent, useState } from "react";
import firebase from "../util/firebase";
import { useSnackbar } from "material-ui-snackbar-provider";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import EmailTextField from "./EmailTextField";
import PasswordTextField from "./PasswordTextField";
import { useBooleanState } from "react-use-object-state";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: theme.palette.primary.light,
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10000,
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: "center",
    maxWidth: 350,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  input: {
    marginBottom: theme.spacing(2),
    marginTop: 0,
  },
  button: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
}));

const Login: FC = () => {
  const classes = useStyles();
  const { showMessage } = useSnackbar();
  const [email, setEmail] = useState("");
  const emailValid = useBooleanState(true);

  const [password, setPassword] = useState("");
  const passwordValid = useBooleanState(true);
  const showPassword = useBooleanState(false);

  const login = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!emailValid.state || !passwordValid.state) {
      return;
    }

    try {
      await firebase.auth().setPersistence("local");
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (e) {
      console.log(e);
      showMessage("Email or password is not correct!");
    }
  };

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.paper}>
        <form onSubmit={login}>
          <Typography variant="h5" className={classes.title}>
            Sign In
          </Typography>
          <EmailTextField
            email={email}
            onChangeEmail={setEmail}
            onChangeValid={emailValid.setState}
            valid={emailValid.state}
            className={classes.input}
          />
          <PasswordTextField
            password={password}
            onChangePassword={setPassword}
            onChangeValid={passwordValid.setState}
            onChangeShowPassword={showPassword.toggle}
            showPassword={showPassword.state}
            className={classes.input}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            size="large"
            type="submit"
          >
            Sign in
          </Button>
        </form>
      </Paper>
    </div>
  );
};

export default Login;
