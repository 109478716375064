import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FC } from "react";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: "rgba(0,0,0,0.2)",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10000,
  },
}));

// eslint-disable-next-line react/prop-types
const Loader: FC<{ isLoading?: boolean }> = ({ isLoading }) => {
  const classes = useStyles();

  if (isLoading === false) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <CircularProgress />
    </div>
  );
};

export default Loader;
