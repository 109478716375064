export const APP_NAME = "LT";

// auth routes
export const SIGNUP_ROUTE = "/signup";
export const SIGNIN_ROUTE = "/signin";
export const RESET_PASSWORD_PATH = `${SIGNIN_ROUTE}/reset-password`;
export const EMAIL_VERIFIED_PATH = "/email-verified";
export const AUTH_ACTION_PATH = "/auth-action";

// app routes
export const APP_LANDING = "/";
export const APP_ANALYTICS_PAGE = "/analytics";
export const APP_BILLING_PAGE = "/billing";
export const APP_SETTINGS_PAGE = "/settings";
export const APP_GRAMMAR_PAGE = "/grammar";
export const APP_SUPPORT_PAGE = "/support";
export const APP_PLAYER_PAGE = "/player";

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyAgBx3KKySpNa9OKCTTvIrM-t9wm6F_oJ4",
  authDomain: "listen-technology.firebaseapp.com",
  projectId: "listen-technology",
  storageBucket: "listen-technology.appspot.com",
  messagingSenderId: "745331326796",
  appId: "1:745331326796:web:00876e7a7b6e33d3a259e9",
  measurementId: "G-V9CJZB6CCD",
};
