import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import DeleteIcon from "@material-ui/icons/Delete";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import firebase from "../util/firebase";
import TextField from "@material-ui/core/TextField";
import { useEffect, useState, useContext } from "react";
import AddNewWord from "./AddNewWord";
import DeleteArticleCache from "./DeleteArticleCache";
import Loader from "./Loader";

import { AdminContext } from "../context/admin";
import Checkbox from "@material-ui/core/Checkbox";
import { useAuthState } from "react-firebase-hooks/auth";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1, 2, 2),
    marginBottom: theme.spacing(2),
    position: "relative",
  },
  sticky: {
    position: "sticky",
    top: theme.spacing(9.5),
    height: "min-content",
  },
  search: {
    marginBottom: theme.spacing(1),
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  table: {
    tableLayout: "fixed",
  },
}));

type Word = {
  id: string;
  original: string;
  replacement: string;
  language: string;
  isDisabled?: boolean;
  updatedAt?: firebase.firestore.Timestamp;
  createdAt?: firebase.firestore.Timestamp;
  isApproved?: boolean;
};

const GrammarPage = () => {
  const classes = useStyles();
  const [user] = useAuthState(firebase.auth());
  const { isAdmin, currentDomains } = useContext(AdminContext);
  const [words, setWords] = useState<Word[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");

  const fetchWords = async () => {
    const db = firebase.firestore();

    setIsLoading(true);

    let query = db
      .collection("words")
      .where("isDisabled", "!=", true)
      .orderBy("isDisabled")
      .orderBy("isApproved", "asc");

    if (!isAdmin) {
      query = query.where("hostnames", "array-contains-any", currentDomains);
    }

    // TODO: add filter by date

    const { docs } = await query.get();

    setWords(
      docs.map((doc) => ({
        id: doc.id,
        original: doc.get("original"),
        replacement: doc.get("replacement"),
        language: doc.get("language"),
        isDisabled: doc.get("isDisabled"),
        isApproved: doc.get("isApproved"),
      }))
    );

    setIsLoading(false);
  };

  useEffect(() => {
    fetchWords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onWordDelete = async (wordId: string) => {
    const db = firebase.firestore();
    const wordDoc = await db.collection("words").doc(wordId);
    wordDoc.update({
      isDisabled: true,
      updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
    });
    setWords(words.filter((word) => word.id !== wordId));
  };

  const onWordApprove = async (wordId: string, isApproved: boolean) => {
    const db = firebase.firestore();
    const wordDoc = await db.collection("words").doc(wordId);
    wordDoc.update({
      isApproved,
      approvedAt: firebase.firestore.Timestamp.fromDate(new Date()),
      approvedBy: user?.email,
    });

    const index = words.findIndex(({ id }) => id === wordId);
    const [item] = words.splice(index, 1);
    const newWords = isApproved
      ? [...words, { ...item, isApproved: true }]
      : [{ ...item, isApproved: false }, ...words];

    setWords(newWords);
  };

  const renderWord = (word: Word) => {
    return (
      <TableRow key={word.id}>
        <TableCell>{word.original}</TableCell>
        <TableCell>{word.replacement}</TableCell>
        <TableCell>{word.language}</TableCell>
        <TableCell align="right">
          <div className={classes.actions}>
            <Checkbox
              disabled={!isAdmin}
              checked={word.isApproved}
              onChange={(event) => onWordApprove(word.id, event.target.checked)}
            />
            <IconButton size="small" onClick={() => onWordDelete(word.id)}>
              <DeleteIcon />
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  const filteredWords =
    search.length > 2
      ? words.filter(({ original }) =>
          original.toLowerCase().includes(search.toLowerCase())
        )
      : words.slice(0, 10);

  const wordsCount = search
    ? words.length - filteredWords.length
    : filteredWords.length;

  return (
    <Grid container spacing={3}>
      <Grid item md={12} lg={6}>
        <Paper className={classes.paper}>
          <Loader isLoading={isLoading} />
          <TextField
            onChange={(e) => setSearch(e.target.value as string)}
            value={search}
            placeholder="Search for a word"
            className={classes.search}
            fullWidth
          />
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Word</TableCell>
                <TableCell>Replacement</TableCell>
                <TableCell>Language</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredWords.map(renderWord)}
              {wordsCount === 0 && (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    There are no words to display
                  </TableCell>
                </TableRow>
              )}
              {!search && wordsCount > 0 && (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    {`And ${words.length - filteredWords.length} more hidden`}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item md={12} lg={6} className={classes.sticky}>
        <Paper className={classes.paper}>
          <AddNewWord onNewWordCreate={fetchWords} />
        </Paper>
        <Paper className={classes.paper}>
          <DeleteArticleCache />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default GrammarPage;
