import { useEffect, useState, FC, createContext } from "react";

import { useSession } from "./useSession";
import Loader from "../component/Loader";
import Permissions from "../component/Permissions";
import Login from "../component/Login";

export type AdminContextType = {
  isAdmin: boolean;
  currentDomains: string[];
  setCurrentDomains: (newCurrentDomains: string[]) => void;
};

export const AdminContext = createContext<AdminContextType>({
  isAdmin: true,
  currentDomains: [],
  setCurrentDomains: () => {},
});

// eslint-disable-next-line react/prop-types
const AdminProvider: FC = ({ children }) => {
  const { user, roles, domains, loading } = useSession();
  const [currentDomains, setCurrentDomains] = useState<string[]>(domains || []);
  const isAdmin = !!roles?.includes("admin");

  useEffect(() => {
    if (domains) {
      setCurrentDomains(domains);
    }
  }, [domains]);

  if (loading) {
    return <Loader />;
  }

  if (!user?.email) {
    return <Login />;
  }

  if (!domains?.length && !isAdmin) {
    // if no domains are assigned and you are not admin
    return <Permissions />;
  }

  return (
    <AdminContext.Provider
      value={{ isAdmin, currentDomains, setCurrentDomains }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminProvider;
