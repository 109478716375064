import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

const PlayerPage = () => {
  const classes = useStyles();

  return <div>Player settings</div>;
};

export default PlayerPage;
