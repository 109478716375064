import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

const BillingPage = () => {
  const classes = useStyles();

  return <div>Billing</div>;
};

export default BillingPage;
