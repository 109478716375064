import { FC, Suspense } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import firebase from "../util/firebase";
import { FIREBASE_CONFIG } from "../constants";
import { SessionProvider } from "../context/useSession";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { SnackbarProvider } from "material-ui-snackbar-provider";

const PRIMARY_LIGHT = "#C7B9FF";
const PRIMARY_MAIN = "#5551FF";
const PRIMARY_DARK = "#C7B9FF";

const SECONDARY_LIGHT = "#0FA958";
const SECONDARY_MAIN = "#FFC700";
const SECONDARY_DARK = "#0FA958";

const theme = createTheme({
  palette: {
    primary: {
      light: PRIMARY_LIGHT,
      main: PRIMARY_MAIN,
      dark: PRIMARY_DARK,
      contrastText: "#fff",
    },
    secondary: {
      light: SECONDARY_LIGHT,
      main: SECONDARY_MAIN,
      dark: SECONDARY_DARK,
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: ["Montserrat", "Lato", "Roboto"].join(","),
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
    MuiCard: {
      root: {
        borderRadius: 8,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
      elevation2: {
        boxShadow: "none",
      },
      rounded: {
        borderRadius: 8,
      },
    },
    MuiStepper: {
      root: {
        background: "none",
        border: "none",
        padding: 0,
      },
    },
  },
});

const App: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <SnackbarProvider SnackbarProps={{ autoHideDuration: 4000 }}>
          <BrowserRouter>
            <Suspense fallback={<div />}>
              <SessionProvider>
                <Router />
              </SessionProvider>
            </Suspense>
          </BrowserRouter>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
